import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  create,
  loadAll,
  loadfilter,
  updateservice,
  getservicehistory,
  loadAllFilter,
  newtyre,
  getserviveinfo,
  updateservicehistory,
} from 'api/services'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { servicesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ServicesInfodata: servicesList,
      },
    })
  }
}

export function* GET_FILTER_DATES({ payload }) {
  console.log('payload', payload)

  const { response } = yield call(loadAllFilter, payload)
  if (response) {
    const { servicesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ServicesInfodata: servicesList,
      },
    })
  }
}

export function* SERVICE_FILTER({ payload }) {
  const { response } = yield call(loadfilter, payload)
  if (response) {
    const { servicesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ServicesInfodata: servicesList,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* CREATE_NEWTYRE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(newtyre, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* CLOSE_SERVICE_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateservice, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_POPUP,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_SERVICE_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateservicehistory, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayModifyPopupModal: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* SHOW_MODIFY_POPUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      displayModifyPopupModal: true,
    },
  })
  const { response } = yield call(getserviveinfo, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      serviceHistory: response?.data?.serviceHistory,
    },
  })
}

export function* GET_SERVICE_HISTORY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(getservicehistory, payload)
  console.log('84', response)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        serviceDetail: response.data.serviceHistory.serviceDetail,
        serviceHistoryDetail: response.data.serviceHistory.serviceHistoryDetail,
        vendorDetail: response.data.serviceHistory.vendorDetail,
        vehicleDetail: response.data.serviceHistory.vehicleDetail,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.GET_FILTER_DATES, GET_FILTER_DATES),
    takeLatest(actions.SERVICE_FILTER, SERVICE_FILTER),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.CREATE_NEWTYRE, CREATE_NEWTYRE),
    takeEvery(actions.CLOSE_SERVICE_REQUEST, CLOSE_SERVICE_REQUEST),
    takeEvery(actions.GET_SERVICE_HISTORY, GET_SERVICE_HISTORY),
    takeEvery(actions.SHOW_MODIFY_POPUP, SHOW_MODIFY_POPUP),
    takeEvery(actions.UPDATE_SERVICE_REQUEST, UPDATE_SERVICE_REQUEST),
  ])
}
