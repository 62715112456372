import React, { Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Tag, DatePicker, Button } from 'antd';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import VehicleAction from 'redux/vehicle/actions';
import { getFormattedDate } from 'utils/parser';
import UpdateFuel from './UpdateFuel';

const { RangePicker } = DatePicker;

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
});

const StoreSettings = ({ apiRes, vehicle, dispatch, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState(apiRes);
  const [dates, setDates] = useState([]);
  const [totalSpent, setTotalSpent] = useState({ amount: 0 });

  useEffect(() => {
    // Set initial filteredData to apiRes when apiRes changes
    setFilteredData(apiRes);
  }, [apiRes]);

  useEffect(() => {
    // Recalculate totalSpent whenever filteredData changes
    if (filteredData?.length > 0) {
      const total = filteredData.reduce((previousValue, currentValue) => ({
        amount: Number(previousValue?.amount) + Number(currentValue?.amount),
      }), { amount: 0 });
      setTotalSpent(total);
    } else {
      setTotalSpent({ amount: 0 });
    }
  }, [filteredData]);

  const showUpdate = values => {
    dispatch({
      type: VehicleAction.SHOW_FUEL_UPDATE,
      payload: {
        fuelInfo: values,
      },
    });
  };

  const hideUpdate = () => {
    dispatch({
      type: VehicleAction.HIDE_FUEL_UPDATE,
    });
  };

  const handleFilter = () => {
    if (dates.length === 0) {
      // If no dates are selected, show all data
      setFilteredData(apiRes);
    } else {
      const [start, end] = dates;
      const filtered = apiRes.filter(item => {
        const receiptDate = moment(item.receipt_date);
        return receiptDate.isBetween(start, end, null, '[]');
      });
      setFilteredData(filtered);
    }
  };

  const handleClear = () => {
    setDates([])
    setFilteredData(apiRes)
  };

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.fuel_variant' }),
      render: (text, record) => (
        <Tag
          color={(() => {
            switch (text?.fuel_variant) {
              case 'petrol':
                return 'red';
              case 'diesel':
                return 'green';
              default:
                return 'blue';
            }
          })()}
          style={{ textTransform: 'capitalize' }}
        >
          {record?.fuel_variant}
        </Tag>
      ),
    },
    {
      title: formatMessage({ id: 'text.amount' }),
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => <>{record?.amount} INR</>,
    },
    {
      title: formatMessage({ id: 'text.kilometers' }),
      dataIndex: 'kilometers',
      key: 'kilometers',
      render: (text, record) => <>{record?.kilometers} KMS</>,
    },
    {
      title: formatMessage({ id: 'text.receipt_date' }),
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.ViewUrl' }),
      render: (text, record) => (
        <button type="button" className="btn btn-light" onClick={() => showUpdate(record)}>
          <i className="fe fe-eye mr-1" aria-hidden="true" />
          {formatMessage({ id: 'action.Edit' })}
        </button>
      ),
    },
  ];

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.fueldata' }),
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="row mb-3">



              <div className="col-md-3">
                <div
                  className="rounded border border-gray-3 mb-3 p-3"
                  style={{ backgroundColor: '#03349A' }}
                  hidden={totalSpent?.amount === undefined}
                >
                  <div
                    className="text-white-5 font-weight-bold font-size-14"
                    style={{ color: '#ffffff' }}
                  >
                    Total Expenses
                  </div>
                  <div
                    className="text-white font-weight-normal font-size-24"
                    style={{ color: '#ffffff' }}
                  >
                    {totalSpent?.amount} INR
                  </div>
                </div>
              </div>
              <div className="col-md-5 mt-5">
                <RangePicker onChange={setDates} />
                <Button className='mx-2' type="primary" onClick={handleFilter}>
                  Filter
                </Button>
                <Button type="primary" onClick={handleClear}>
                  Clear
                </Button>
              </div>

            </div>


            <div className="kit__utils__table">
              <Suspense fallback={<Spin />}>
                {filteredData?.length >= 0 ? (
                  <Table
                    dataSource={filteredData}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current);
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
            </div>
            <UpdateFuel
              visible={vehicle?.displayUpdateFuelDrawer}
              title={`Update Fuel - ${vehicle?.IndividualVehicleInfodata?.vehicle_reg_no}`}
              description={formatMessage({ id: 'text.updateFuel.description' })}
              onCancel={hideUpdate}
              loading={vehicle?.updatingFuel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(injectIntl(StoreSettings));
