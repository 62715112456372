const actions = {
  SET_STATE: 'vehicle/SET_STATE',
  REMOVE_DATA: 'vehicle/REMOVE_DATA',
  SHOW_CREATE: 'vehicle/SHOW_CREATE',
  HIDE_CREATE: 'vehicle/HIDE_CREATE',
  SHOW_UPDATE: 'vehicle/SHOW_UPDATE',
  HIDE_UPDATE: 'vehicle/HIDE_UPDATE',
  SHOW_DELETE: 'vehicle/SHOW_DELETE',
  HIDE_DELETE: 'vehicle/HIDE_DELETE',
  SHOW_LINK_DRIVER: 'vehicle/SHOW_LINK_DRIVER',
  HIDE_LINK_DRIVER: 'vehicle/HIDE_LINK_DRIVER',
  UPDATE_DRIVER: 'vehicle/UPDATE_DRIVER',
  SHOW_FUEL_DRAWER: 'vehicle/SHOW_FUEL_DRAWER',
  HIDE_FUEL_DRAWER: 'vehicle/HIDE_FUEL_DRAWER',
  SHOW_DELETE_DOCUMENT: 'vehicle/SHOW_DELETE_DOCUMENT',
  HIDE_DELETE_DOCUMENT: 'vehicle/HIDE_DELETE_DOCUMENT',

  LOAD_ALL: 'vehicle/LOAD_ALL',
  CREATE: 'vehicle/CREATE',
  UPDATE: 'vehicle/UPDATE',
  REMOVE: 'vehicle/REMOVE',
  SHOW_DOC_DRAWER: 'vehicle/SHOW_DOC_DRAWER',
  HIDE_DOC_DRAWER: 'vehicle/HIDE_DOC_DRAWER',
  REMOVE_DOCUMENT: 'vehicle/REMOVE_DOCUMENT',
  SHOW_FUEL_UPDATE: 'vehicle/SHOW_FUEL_UPDATE',
  HIDE_FUEL_UPDATE: 'vehicle/HIDE_FUEL_UPDATE',
  UPDATE_FUEL_INFO: 'vehicle/UPDATE_FUEL_INFO',

  LOAD_SINGLE_VEHICLE: 'vehicle/LOAD_SINGLE_VEHICLE',
  LOAD_SINGLE_DRIVER: 'vehicle/LOAD_SINGLE_DRIVER',
  LOAD_SINGLE_SERVICE: 'vehicle/LOAD_SINGLE_SERVICE',
  LOAD_SINGLE_FUEL: 'vehicle/LOAD_SINGLE_FUEL',
  LOAD_SINGLE_VEHICLE_DOCUMENT: 'vehicle/LOAD_SINGLE_VEHICLE_DOCUMENT',
  SERVICE_FILTER: 'vehicle/SERVICE_FILTER',
  LOAD_REPORT: 'vehicle/LOAD_REPORT',
  UPLOAD_VEHICLE_DOCUMENTS: 'vehicle/UPLOAD_VEHICLE_DOCUMENTS',
  LOAD_SINGLE_VEHICLE_EXPENSES: 'vehicle/LOAD_SINGLE_VEHICLE_EXPENSES',



  LOAD_SERVICES_REQUEST: 'LOAD_SERVICES_REQUEST',
  LOAD_SERVICES_SUCCESS: 'LOAD_SERVICES_SUCCESS',
  LOAD_SERVICES_FAILURE: 'LOAD_SERVICES_FAILURE',
}

export default actions
