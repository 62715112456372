import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, InputNumber, Form, Button, Select, Switch } from 'antd'
import { injectIntl } from 'react-intl'
import VendorAction from 'redux/vendor/actions'
// import MaskedInput from 'antd-mask-input'
import { vendorcategory } from 'utils/data'
// import { phoneNumberNational, phoneNumberE164 } from 'utils/parser'
import CardTitle from 'components/app/common/CardTitle'

const { Option } = Select

const mapStateToProps = ({ dispatch, vendor }) => ({
  dispatch,
  vendor,
})

const StoreSettings = ({ dispatch, apiRes, vendor, intl: { formatMessage } }) => {
  const [form] = Form.useForm()

  const AccountDatas = apiRes

  const ApiDatas = AccountDatas

  const onFinish = values => {
    // if (values.mobile_number) values.mobile_number = phoneNumberE164(values.mobile_number)
    const requestPayload = {
      name: values?.name,
      owner_name: values?.owner_name,
      gst_no: values?.gst_no,
      mobile_number: values?.mobile_number,
      phone: values?.phone,
      manager_name: values?.manager_name,
      address: values?.address,
      city: values?.city,
      state: values?.state,
      pincode: values?.pincode,
      category: values?.category,
      status: values?.status,
    }
    dispatch({
      type: VendorAction.UPDATE,
      payload: {
        updatedData: requestPayload,
        vendorId: ApiDatas?._id,
      },
    })
  }

  useEffect(() => {
    dispatch({
      type: VendorAction.LOAD_ALL,
    })
  }, [dispatch])

  useEffect(() => {
    if (AccountDatas) {
      form.setFieldsValue({
        name: ApiDatas?.name,
        owner_name: ApiDatas?.owner_name,
        gst_no: ApiDatas?.gst_no,
        // mobile_number: phoneNumberNational(ApiDatas?.mobile_number),
        mobile_number: ApiDatas?.mobile_number,
        phone: ApiDatas?.phone,
        manager_name: ApiDatas?.manager_name,
        address: ApiDatas?.address,
        city: ApiDatas?.city,
        state: ApiDatas?.state,
        pincode: ApiDatas?.pincode,
        category: ApiDatas?.category,
        status: ApiDatas?.status,
      })
    }
  })

  return (
    <div>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        form={form}
        initialValues={{
          ...ApiDatas,
          name: ApiDatas?.name,
          mobile_number: ApiDatas?.mobile_number,
          // mobile_number: phoneNumberNational(ApiDatas?.mobile_number),
        }}
      >
        <div className="card">
          <div className="card-body">
            <CardTitle title={formatMessage({ id: 'text.VehicleDetails' })} />
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="name"
                  label={formatMessage({ id: 'form.label.name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="owner_name"
                  label={formatMessage({ id: 'form.label.owner_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.owner_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="category"
                  label={formatMessage({ id: 'form.label.category' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={formatMessage({ id: 'form.placeholder.category' })}
                    size="large"
                  >
                    {vendorcategory?.map(o => (
                      <Option key={o.key} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="gst_no"
                  label={formatMessage({ id: 'form.label.gst_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.gst_no' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              {/* <div className="col-md-6">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const phoneNumber = phoneNumberNational(value)
                        if (!value || (phoneNumber && phoneNumber.length === 14)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                        )
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="(111) 111-1111"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item>
              </div> */}
              <div className="col-md-6">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    maxLength={10}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="phone"
                  label={formatMessage({ id: 'form.label.phone' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.phone' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="manager_name"
                  label={formatMessage({ id: 'form.label.manager_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.manager_name' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="address"
                  label={formatMessage({ id: 'form.label.address' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.address' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="city"
                  label={formatMessage({ id: 'form.label.city' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.city' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="state"
                  label={formatMessage({ id: 'form.label.state' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.state' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="pincode"
                  label={formatMessage({ id: 'form.label.pincode' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.pincode' })}
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="status"
                  label={formatMessage({ id: 'form.label.user_status' })}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={formatMessage({ id: 'action.active' })}
                    unCheckedChildren={formatMessage({ id: 'action.inactive' })}
                  />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button type="primary" htmlType="submit" size="large" loading={vendor?.loading}>
                    {formatMessage({ id: 'action.update' })}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
