import actions from './actions'

const initialState = {
  Vehicleinfo: null,
  VehicleInfodata: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  serviceReport: null,
  filterDetail: null,

  IndividualDriverInfodata: null,
  IndividualVehicleInfodata: null,
  IndividualServiceInfodata: null,
  IndividualFuelInfodata: null,

  searchVehicle: null,
  search: null,
  company_data: null,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayRemoveModal: false,
  removing: false,

  displayDriverUpdate: false,

  displayFuelModel: false,

  displayRemoveDocumentModal: false,

  displayUpdateFuelDrawer: false,
  updatingFuel: false,

  displayDocumentUploadDrawer: false,
  uploading: false,

  services: [],
  loadingServices: false,
  servicesError: null,

}

// export default function vehicleReducer(state = initialState, action) {
//   switch (action.type) {
//     case actions.SET_STATE:
//       return { ...state, ...action.payload }
//     case actions.ADD_DATA:
//       return {
//         ...state,
//         data: [...state.data, action.payload.data],
//       }

//     case actions.SHOW_DOC_DRAWER:
//       return { ...state, displayDocumentUploadDrawer: true }
//     case actions.HIDE_DOC_DRAWER:
//       return { ...state, displayDocumentUploadDrawer: false }

//     case actions.SHOW_UPDATE:
//       return { ...state, selectedData: action.payload.Vehicleinfo, displayUpdateDrawer: true }
//     case actions.HIDE_UPDATE:
//       return { ...state, selectedData: null, displayUpdateDrawer: false }

//     case actions.SHOW_FUEL_UPDATE:
//       return { ...state, selectedData: action.payload.fuelInfo, displayUpdateFuelDrawer: true }
//     case actions.HIDE_FUEL_UPDATE:
//       return { ...state, selectedData: null, displayUpdateFuelDrawer: false }

//     case actions.SHOW_LINK_DRIVER:
//       return { ...state, selectedData: action.payload.driverInfo, displayDriverUpdate: true }
//     case actions.HIDE_LINK_DRIVER:
//       return { ...state, selectedData: null, displayDriverUpdate: false }

//     case actions.REMOVE_DATA:
//       return {
//         ...state,
//         displayRemoveModal: false,
//         data: state.data.filter(o => action.payload._id !== o._id),
//       }
//     case actions.SHOW_CREATE:
//       return { ...state, displayAddDrawer: true }
//     case actions.HIDE_CREATE:
//       return { ...state, displayAddDrawer: false }

//     case actions.SHOW_DELETE_DOCUMENT:
//       return {
//         ...state,
//         selectedData: action.payload.vehicleInfo,
//         displayRemoveDocumentModal: true,
//       }
//     case actions.HIDE_DELETE_DOCUMENT:
//       return { ...state, displayRemoveDocumentModal: false }
//     case actions.SHOW_DELETE:
//       return { ...state, selectedData: action.payload.vehicleInfo, displayRemoveModal: true }
//     case actions.HIDE_DELETE:
//       return { ...state, displayRemoveModal: false }
//     case actions.SHOW_FUEL_DRAWER:
//       return { ...state, selectedData: action.payload?.vehicleInfo, displayFuelModel: true }
//     case actions.HIDE_FUEL_DRAWER:
//       return { ...state, displayFuelModel: false }
//     default:
//       return state
//   }
// }

export default function vehicleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.ADD_DATA:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    case actions.SHOW_DOC_DRAWER:
      return { ...state, displayDocumentUploadDrawer: true };

    case actions.HIDE_DOC_DRAWER:
      return { ...state, displayDocumentUploadDrawer: false };

    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.Vehicleinfo, displayUpdateDrawer: true };

    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false };

    case actions.SHOW_FUEL_UPDATE:
      return { ...state, selectedData: action.payload.fuelInfo, displayUpdateFuelDrawer: true };

    case actions.HIDE_FUEL_UPDATE:
      return { ...state, selectedData: null, displayUpdateFuelDrawer: false };

    case actions.SHOW_LINK_DRIVER:
      return { ...state, selectedData: action.payload.driverInfo, displayDriverUpdate: true };

    case actions.HIDE_LINK_DRIVER:
      return { ...state, selectedData: null, displayDriverUpdate: false };

    case actions.REMOVE_DATA:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter(o => action.payload._id !== o._id),
      };

    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true };

    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false };

    case actions.SHOW_DELETE_DOCUMENT:
      return {
        ...state,
        selectedData: action.payload.vehicleInfo,
        displayRemoveDocumentModal: true,
      };

    case actions.HIDE_DELETE_DOCUMENT:
      return { ...state, displayRemoveDocumentModal: false };

    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.vehicleInfo, displayRemoveModal: true };

    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false };

    case actions.SHOW_FUEL_DRAWER:
      return { ...state, selectedData: action.payload?.vehicleInfo, displayFuelModel: true };

    case actions.HIDE_FUEL_DRAWER:
      return { ...state, displayFuelModel: false };

    case actions.LOAD_SERVICES_REQUEST:
      return { ...state, loadingServices: true, servicesError: null };

    case actions.LOAD_SERVICES_SUCCESS:
      return { ...state, services: action.payload, loadingServices: false };

    case actions.LOAD_SERVICES_FAILURE:
      return { ...state, servicesError: action.payload, loadingServices: false };

    default:
      return state;
  }
}

